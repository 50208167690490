#navbar {
    width: 100px;
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 20px 0;
}

.blue-bar {
    background-color: #0cbbe7;
    border-radius: 50px;
    display: flex;
    padding: 20px 0 0 0;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 20px;
    height: 95vh;
}

.logo {
    width: 60px;
    height: 60px;
    background-color: var(--white);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 50px;
    height: 50px;
}

#navbar ul {
    list-style: none;
    margin: 20px 0 50px;
    padding: 0;
}

#navbar ul li {
    padding: 0;
    margin: 20px 0;
    font-size: 28px;
}

#navbar ul li a {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms;
}

#navbar ul li a:hover,
#navbar ul li a.active {
    background-color: #39d3fa;
    transform: scale(1.15);
}

.btn-chat {
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    transition: all 400ms;
}

.btn-chat span {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.btn-chat:hover {
    opacity: 0.8;
    transform: scale(1.05);
}