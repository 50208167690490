.lesson-item,
.exam-item {
    position: relative;
    padding-bottom: 50px;
}
.lesson-item .btn.btn-primary,
.exam-item .btn.btn-primary {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
}

.lesson-card {
  border-radius: 20px !important;
  flex: 1;
  position: relative;
}

.lesson-card .card-body {
  padding: 8px;
  overflow-y: auto;
  height: 215px;
}

.lesson-card .card-body::-webkit-scrollbar {
  display: none;
}

.lesson-image {
  height: 150px;
  border-radius: 11px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 5px;
}

.lesson-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.lesson-basic-info {
  line-height: 20px;
}

.lesson-status .badge {
  border-radius: 10px;
  font-weight: 400;
}

.lesson-status .badge.bg-primary {
  background-color: var(--CyanProcess) !important;
}

.lesson-status .badge.bg-secondary {
  background-color: var(--CerisePink) !important;
}

.lesson-partial-info {}

.lesson-topic {
  background-image: url(../../assets/images/add-button.png);
  background-position: 0 3px;
  background-size: 15px;
  background-repeat: no-repeat;
  font-size: 12px;
  padding-left: 17px;
  margin-bottom: 0;
}

.lesson-url {
  background-image: url(../../assets/images/hyperlink.png);
  background-position: 0 3px;
  background-size: 14px;
  background-repeat: no-repeat;
  font-size: 12px;
  padding-left: 17px;
  margin-bottom: 0;
}

.lesson-attachment {
  background-image: url(../../assets/images/attachment.png);
  background-position: 0 3px;
  background-size: 14px;
  background-repeat: no-repeat;
  font-size: 12px;
  padding-left: 17px;
  margin-bottom: 0;
}

.lesson-url a,
.lesson-attachment a {
  text-decoration: none;
  color: #222529;
}

.lesson-actions {
  background-color: transparent !important;
  border: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.lesson-actions i {}

.lesson-action-dd {
  right: 15px;
  min-width: 120px !important;
  list-style: none;
  padding: 3px 0 !important;
  top: 98%;
}

.lesson-action-dd button {
  border: 0;
  background-color: transparent;
  font-size: 13px;
  width: 100%;
  text-align: left;
  padding: 0 10px;
}

.lesson-action-dd button:hover {
  background-color: var(--gray);
}