.word-match-to {
    padding: 10px 20px;
    margin-bottom: 5px;
    background-color: #e7e7e7;
    border: 2px dashed #e7e7e7;
    border-radius: 30px;
}
.word-to-match {
    border: 2px dashed #e7e7e7;
    padding: 10px 20px;
    cursor: move;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
}
.exam-title {
    background: #fff;
    border-radius: 30px;
    padding: 0 25px;
    line-height: 67px;
}
.submit-exam-btn {
    padding: 0;
    border: none;
}
#exam-qs .card:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
#exam-qs .card:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 0 !important;
}
.q-card-header {
    border-bottom: #9b9b9b 1px solid;
    margin: 0 !important;
}
.q-card-header .badge {
    border-radius: 15px;
    font-size: 14px;
    font-weight: normal;
}
.q-type {
    color: #8e8e8e;
}
.form-check-inline {
    background-color: #e7e7e7;
    padding: 5px;
    padding-right: 15px !important;
    padding-left: 36px !important;
    border-radius: 18px;
}
.fitb {
    margin: 0 5px;
    border-top: none;
    border-right: none;
    border-left: none;
}
#exam-qs {
    height: calc(100vh - 160px);
    overflow-y: scroll;
    padding-right: 10px;
    margin-left: 300px;
    transition: margin-left 300ms;
}
#exam-qs::-webkit-scrollbar {
    display: none;
}

/* #exam-qs::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

#exam-qs::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: rgba(0, 0, 0, 0.3);
} */
.ql-btn {
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 25px;
    background-color: #88cd8e;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1;
}
.q-list-nums {
    border: 20px solid rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 20px;
    height: calc(100vh - 160px);
    width: 280px;
    position: absolute;
    left: 10px;
    transition: left 300ms;
}
.q-list-nums-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    max-height: calc(100vh - 287px);
    overflow-y: scroll;
}
.q-list-nums-grid button {
    border: #ee3a81 2px solid;
    border-radius: 10px;
    height: 60px;
    background-color: white;
    color: #ee3a81;
    font-size: 23px;
    font-weight: 500;
}
.q-list-nums-grid button.attempted {
    border: #00d045 2px solid;
    background-color: #00d045;
    color: #fff;
}
.q-list-nums-grid button.attemptlater {
    border: #fad06a 2px solid;
    background-color: #fad06a;
    color: #fff;
}
.q-list-nums-grid::-webkit-scrollbar {
    display: none;
}
.mark-attempt-later-btn {
    border: none;
    border-radius: 20px;
    background-color: #fad06a;
}
