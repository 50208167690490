.section-heading .image {
  height: 20px;
  margin-right: 10px;
}
.section-heading {
  border-bottom: solid 1px #7b7b7b;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.lesson-plan {
  background-image: url(../../assets/images/pegboard-bg.png);
  background-repeat: repeat;
  background-position: 10px 12px;
  /* width: 1375px; */
  width: 100%;
  min-width: 1370px;
  position: relative;
  overflow: hidden;
}
.lesson-view .border-left {
  border-left: #7b7b7b solid 1px;
}
.lesson-view .px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.lesson-view .lesson-title-sub {
  font-size: 15px;
  color: #7b7b7b;
  text-align: center;
}
.lesson-view .lesson-image {
  height: 195px;
}
.lesson-view .box-shadow {
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
}
.lesson-view .br-20 {
  border-radius: 20px;
}
.lesson-view .lesson-title {
  font-size: 22px;
  color: #7b7b7b;
}
.lesson-view .lesson-title-sub2 {
  font-size: 13px;
  color: #7b7b7b;
}
.div-box-theme {
  border: solid 1px #c9c9c9;
  border-radius: 20px;
  flex: 1 1;
  position: relative;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding: 10px;
  margin-bottom: 10px;
}
.div-box-theme .image {
  height: 35px;
}




