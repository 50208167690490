#chat-drawer .offcanvas-body::-webkit-scrollbar {
    display: none;
}

#chat-drawer .offcanvas-header {
    flex-direction: column;
}

#chat-drawer .offcanvas-header .chat-drawer-header {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
}

#chat-drawer .offcanvas-header .chat-drawer-search {
    width: 100%;
}

.cc-item {
    background: #f3f3f3;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    cursor: pointer;
    transition: all 400ms;
    position: relative;
}

.cc-item:hover {
    background: rgb(253 237 202 / 40%);
}

.cc-thumb {
    margin-right: 10px;
}

.chat-count {
    display: block;
    width: 25px;
    height: 25px;
    background-color: rgb(220, 53, 69);
    color: var(--white);
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    border-radius: 13px;
    position: absolute;
    top: -10px;
    right: -7px;
}

#chat-box {
    max-width: 400px;
    width: 35vw;
    height: 80vh;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    right: 415px;
    background-color: var(--white);
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 0;
}

#chat-box .card-header,
#chat-box .card-footer {
    background-color: var(--white);
}

#chat-box .card-footer {
    padding: 10px;
}

.chat-window-details {
    /* height: calc(80vh - 102px); */
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column-reverse;
}

.chat-window-details::-webkit-scrollbar {
    display: none;
}

.chat-del-btn {
    background-color: transparent;
    width: 25px;
    height: 25px;
    border: 0px;
    border-radius: 13px;
    margin-left: 3px;
}

.chat-message {
    position: relative;
}

.load-more-msgs {
    text-align: center;
    padding-bottom: 10px;
    font-size: 14px;
}

.load-more-msgs a {
    text-decoration: none;
    color: var(--black);
}

.btn-chat {
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    transition: all 400ms;
    position: relative;
}

.btn-chat span {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    color: var(--white);
    font-weight: 700;
    font-size: 18px;
}

.btn-chat:hover {
    opacity: 0.8;
    transform: scale(1.05);
}

.btn-chat small {
    display: block;
    width: 25px;
    height: 25px;
    background-color: rgb(220, 53, 69);
    color: var(--white);
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    border-radius: 13px;
    position: absolute;
    top: -7px;
    right: -7px;
}