#assignments {
    padding-bottom: 0;
}
#assignments .card {
    margin-bottom: 20px;
    height: 100%;
}
#assignments .card:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
#assignments .card:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 0 !important;
}
._37kfa {
    display: none !important;
}
._2fG9h {
    padding: 0 !important;
}
._2gd2_ {
    margin-left: 0 !important;
}
._1ceqH {
    border-radius: 10px !important;
    overflow: hidden;
}
.pic-prev {
    border: 3px dashed #cdcdcd;
    border-radius: 10px;
    padding: 20px;
}
