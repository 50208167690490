.loading-overlay {
    height: 100%;
    top: 0;
    position: absolute;
    z-index: 100;
    width: calc(100% - 40px);
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
}