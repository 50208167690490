#page-content {
  flex: 1;
  padding: 20px 0px 20px 118px;
}

[dir='rtl'] #page-content {
  flex: 1;
  padding: 20px 118px 20px 0px;
}

#timeline {
  padding: 20px 0;
  display: flex;
  position: relative;
}

.timeline-inner {
  background-color: var(--VividMalachite);
  border-radius: 40px;
  width: 400px;
  padding: 20px 10px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.timeline-dashboard .timeline-inner {
  margin-bottom: 40px;
}

.timeline-dashboard #timeline {
  padding: 20px 0;
  display: flex;
  position: fixed;
}

.timeline-dashboard #timeline.timeline-rtl {
  left: 0
}

.timeline-dashboard #timeline.timeline-ltr {
  right: 0
}

#timeline h4 {
  text-transform: uppercase;
  font-weight: 700;
}

#timeline .card {
  border-color: #8891a3;
  border-radius: 20px;
}

#timeline .card .card-body {
  padding-bottom: 0;
}

#timeline .card .card-footer {
  background-color: transparent;
  border-color: #e0e0e0;
}

#timeline .card .card-footer a {
  color: #a4a4a4;
  text-decoration: none;
  transition: all 500ms;
  font-size: 14px;
}

#timeline .card .card-footer a:hover {
  color: #0a58ca;
}

.card-type {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
}

.card-icon {
  /* 
    background-color: #0cbbe7;
    border-radius: 100%; */
  min-width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.card-share {
  color: #a4a4a4;
  text-decoration: none;
  transition: all 500ms;
  font-size: 11px;
  display: flex;
  align-items: center;
}

.card-desc {
  font-size: 13px;
  margin-bottom: 10px;
}

.timeline-reel {
  overflow-y: auto;
  padding: 0 10px;
  height: calc(100vh - 57px);
}

.timeline-reel .card:last-child {
  /* margin-bottom: 0 !important; */
}

.timeline-reel::-webkit-scrollbar {
  display: none;
}

#dashboard-ut #timeline {
  padding: 0;
}

#dashboard-ut .timeline-inner {
  border-radius: 0;
  padding-bottom: 0;
}

/* .timeline-reel::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

.timeline-reel::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: rgba(0, 0, 0, 0.3);
} */
.reel-load-more {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  bottom: 50px;
  position: absolute;
  width: calc(100% - 50px);
}

.slider-wrap {
  /* width: calc(100vw - 550px); */
  margin-bottom: 30px;
}

.slider-wrap .slick-prev,
.slider-wrap .slick-next {
  color: var(--CerisePink) !important;
  top: -26px !important;
  left: calc(100% - 25px);
}

.slider-wrap .slick-prev {
  left: 92% !important;
}

.slider-wrap h2 {
  color: #0cbbe7;
  font-weight: 700;
  font-size: 27px;
  margin-bottom: 20px;
}

.one-slider {
  margin-top: 20px;
  margin-bottom: 0 !important;
}

.one-slide {
  padding-bottom: 10px;
}

.two-slide .slide-inner {
  text-align: center;
}

.one-slide .slide-inner {
  background-color: #a4a4a4;
  margin: 0 10px 0 5px;
  height: 120px;
  border-radius: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 350ms;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 20%); */
}

.one-slide .slide-inner:hover {
  transform: scale(1.04);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.one-slide .slide-inner h3 {
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  padding-left: 20px;
}

.one-slide .slide-inner h3::after {
  content: '';
  display: block;
  width: 85px;
  height: 2px;
  background: rgba(255, 255, 255, 0.6);
  margin-top: 5px;
}

.one-slide a {
  text-decoration: none;
}

.hs-1 .slide-inner {
  background-color: #00d045;
  box-shadow: 5px 10px 35px 5px rgba(8, 218, 78, 0.3);
}

.hs-2 .slide-inner {
  background-color: #0cbbe7;
  box-shadow: 10px 10px 20px 2px rgba(45, 182, 250, 0.4);
}

.hs-3 .slide-inner {
  background-color: #ee3a81;
  box-shadow: 5px 10px 35px 5px rgba(233, 34, 44, 0.24);
}

.hs-4 .slide-inner {
  background-color: #3c63ae;
  box-shadow: -10px 10px 20px 2px rgb(60, 99, 174, 0.3);
}

.one-slide .slide-inner img {
  position: absolute;
  max-width: 50%;
  width: auto;
  height: 145px;
  right: 0;
  bottom: 0;
}

.one-slider {
  margin-bottom: 35px;
}

.one-slider .slick-list {
  padding-top: 30px;
  height: 200px;
}

.two-slider {
  margin-bottom: 25px;
}

.slide-icon {
  width: 50px;
  height: 50px;
  background-color: #0cbbe7;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 250ms;
  font-size: 25px;
  margin: 0 auto;
}

.slide-icon:hover {
  /* opacity: 0.8; */
}

.two-slide {
  line-height: 20px;
}

.two-slide a {
  text-decoration: none;
}

.two-slide a h6 {
  color: black;
}

.two-slide h6 {
  padding-top: 5px;
  margin-bottom: 0;
}

.two-slide span {
  color: #000;
}

.three-slide {
  width: calc(25% - 5px);
  margin: 0 2.5px 15px;
}

.three-slider {
  margin-bottom: 0;
  /* height: 220px; */
  overflow-y: hidden;
  padding: 10px 5px 0;
}

.three-slider a {
  text-decoration: none;
}

.three-slider .slick-slide {
  padding: 40px 0 20px;
}

.three-slide .slide-inner {
  /* background-color: #7c7c7c; */
  /* border: #fff 5px solid; */
  border-radius: 15px;
  /* overflow: hidden; */
  margin: 0 5px;
  text-align: center;
  color: #7c7c7c;
  cursor: pointer;
  transition: all 250ms;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 7%);
  padding: 5px;
}

.three-slide .slide-inner:hover .subject-image {
  transform: scale(1.05);
}

.subject-image {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  margin-bottom: 8px;
  border-radius: 12px;
  /* margin-top: -40px; */
  transition: all 250ms;
}

.three-slide .slide-inner img {
  margin-bottom: 10px;
  border-radius: 13px;
}

.btn-filter {
  margin-top: 5px;
  cursor: pointer;
  font-size: 20px;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0 !important;
}

.more-images {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 7px;
  margin: 0 10px 0 5px;
  width: 89%;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lb-thumb {
  cursor: pointer;
}

.exam-details {
  font-size: 13px;
}

.poll-options label {
  font-size: 13px;
  cursor: pointer;
}

.survey-carousel .carousel {
  padding-bottom: 40px;
}

.survey-carousel .carousel-control-prev,
.survey-carousel .carousel-control-next {
  background-color: rgb(255, 200, 0);
  opacity: 1;
  height: 30px;
  bottom: 0;
  top: auto;
  right: 0;
}

.survey-carousel .carousel-control-prev {
  right: 46px;
  left: auto;
}

.survey-carousel .carousel-control-next-icon,
.survey-carousel .carousel-control-prev-icon {
  width: 15px;
  height: 15px;
}

.survey-carousel .slick-slider {
  padding-bottom: 16px;
}

.survey-carousel .slick-prev,
.survey-carousel .slick-next {
  color: #212529 !important;
  bottom: 0 !important;
  left: calc(100% - 30px);
  background-color: rgb(255, 200, 0);
  padding: 10px;
  width: 10px;
  height: 12px;
  top: auto;
  z-index: 1;
  border-radius: 4px;
}

.survey-carousel .slick-prev {
  left: calc(100% - 61px) !important;
}

.survey-carousel .slick-prev:hover,
.survey-carousel .slick-next:hover {
  background-color: rgb(255, 213, 63);
}

.survey-slide {
  font-size: 13px;
  position: relative;
  padding-bottom: 40px;
}

.survey-slide-footer {
  position: absolute;
  bottom: 22px;
}

.scale-survey {
  display: flex;
  flex-direction: row;
}

.survey-scale-select {
  border: #ee3a81 2px solid;
  margin-right: 2px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: #ee3a81;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  transition: all 250ms;
  flex: 1;
  position: relative;
}

.survey-scale-select:hover {
  opacity: 0.8;
}

.survey-radio {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.survey-scale-select.selected-scale {
  background-color: #fff !important;
  color: #ee3a81;
}

.mrq-survey {
  display: flex;
  flex-wrap: wrap;
}

.mrq-item {
  display: flex;
  flex: 50%;
  width: 100%;
}

.dashboard-timetable .dropdown-toggle:hover {
  background-color: auto !important;
}

.timetable-buttons {
  background-color: #fff !important;
}

.weekday-btns {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

.weekday-btns .weekday-btn {
  flex: 1;
}

.weekday-btns .weekday-btn:first-child {
  border-top-left-radius: 15px;
}

.weekday-btns .weekday-btn:last-child {
  border-top-right-radius: 15px;
}

.weekday-btn.btn-light {
  background-color: #fff;
}

.dashboard-timetable-table {
  border-collapse: separate;
  margin: 0 !important;
}

.dashboard-timetable-table th {
  background-color: #fff !important;
  font-size: 13px;
  font-weight: normal;
}

.dashboard-timetable-table td {
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.dashboard-timetable-table tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

.dashboard-timetable-table tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

.like-popup-btn:hover+.like-popup {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.like-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px;
  top: -30px;
  visibility: hidden;
  opacity: 0;
  transition: all 200ms;
  width: 130px;
  left: 0;
}

.like-popup:hover {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.like-popup img {
  position: relative;
  top: -2px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 200ms;
}

.like-popup img:hover {
  top: -7px;
}


.comment-item {
  display: flex;
  padding-bottom: 15px;
  position: relative;
}

.comment-item:last-child {
  padding-bottom: 0;
}

.comment-body {
  flex: 1;
}

.comment-body .card-body {
  background-color: var(--gray);
}

.commenter-dp {
  z-index: 1;
}

.comment-footer {
  display: flex;
  justify-content: space-between;
}

.parent-comment.has-child-comments::before {
  content: '';
  width: 1px;
  height: 100%;
  border-left: #cdcdcd 2px solid;
  position: absolute;
  left: 25px;
  top: 0;
}

.child-comment::before {
  content: '';
  width: 1px;
  height: 100%;
  border-left: #cdcdcd 2px solid;
  position: absolute;
  left: 25px;
  top: 0;
}

.child-comment::after {
  content: '';
  width: 25px;
  height: 27px;
  border-bottom: #cdcdcd 2px solid;
  position: absolute;
  left: 25px;
  top: 0;
}

.last-child-comment::before {
  content: '';
  width: 25px;
  height: 27px;
  border-left: #cdcdcd 2px solid;
  border-bottom: #cdcdcd 2px solid;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 25px;
  top: 0;
}

.btn-view-conv {
  border: 0;
  background-color: transparent;
  font-size: 0.875em;
  color: #6c757d !important;
  padding: 0;
}

.btn-cancel-conv {
  border: 0;
  background-color: transparent;
  font-size: 0.875em;
  color: rgb(220, 53, 69) !important;
  font-weight: bold;
  padding: 0;
}


/* RTL */
[dir='rtl'] .child-pic {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}

.slick-dots {
  bottom: 5px !important
}

.slick-dots li {
  width: 10px !important;
  margin: 0 1px !important;
}

.slick-dots li button {
  width: 10px !important;
}

.slick-dots li button:before {
  width: 10px !important;
}

[dir='rtl'] .slider-wrap .slick-prev,
[dir='rtl'] .slider-wrap .slick-next {
  color: var(--CerisePink) !important;
  top: -30px !important;
  left: 50px;
}

[dir='rtl'] .slider-wrap .slick-prev {
  left: 7px !important;
}

[dir='rtl'] .one-slide .slide-inner,
[dir='rtl'] .three-slide .slide-inner {
  margin-right: 0;
  margin-left: 10px;
}

[dir='rtl'] .subjects-slider {
  margin-bottom: 60px;
}

[dir='rtl'] .two-slide {
  direction: rtl;
}

[dir='rtl'] .slide-icon {
  margin: 0 auto;
}

[dir='rtl'] .card-icon {
  margin-right: 0;
  margin-left: 5px;
}

[dir='rtl'] .survey-carousel .slick-next {
  left: auto !important;
  right: 0 !important;
}

[dir='rtl'] .survey-carousel .slick-slide {
  float: left !important;
  direction: rtl;
}

[dir='rtl'] .react-stars span {
  float: right !important;
}

[dir='rtl'] .one-slide .slide-inner img {
  right: auto;
  left: 15px;
}

[dir='rtl'] .one-slide .slide-inner h3 {
  padding-left: 0;
  padding-right: 20px;
}

[dir='rtl'] .weekday-btns .weekday-btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 15px;
}

[dir='rtl'] .weekday-btns .weekday-btn:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 15px;
}

[dir='rtl'] .dashboard-timetable-table tr:last-child td:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 20px;
}

[dir='rtl'] .dashboard-timetable-table tr:last-child td:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}


img.img-fit-thumb {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}


.listgroup {
  border: solid 1px #c9c9c9;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.listgroup .image {
  width: 20px;
  height: 20px;
  cursor: pointer
}

.listgroup .title {
  margin-left: 10px;
  margin-right: 10px;
}


.section-heading {
  border-bottom: solid 1px #7b7b7b;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.section-heading .heading {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  opacity: 0.7;
}

.section-heading .image {
  height: 20px;
  margin-right: 10px;
}

.no-one-slider {
  display: flex;
  flex-wrap: wrap;
}

.no-one-slider .one-slide {
  width: 25%;
  margin: 20px 0;
}

.ut-toggle {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--VividMalachite);
  color: #fff;
  border: 0;
  height: 80px;
  width: 40px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  font-size: 30px;
}

[dir='rtl'] .ut-toggle {
  left: 0;
  right: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}