.class-view-timeline .card:last-child {
  margin-bottom: 0 !important;
}

.class-view-timeline .exam-details,
.class-view-timeline .card-desc-para {
  display: flex;
  justify-content: space-between;
}

.class-view-timeline .card-desc {
  margin-bottom: 0;
}

.card-date {
  font-size: 12px;
}

.class-view-timeline .card-action button {
  font-size: 10px;
}

.class-view-ut-card {
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 20px !important;
}

.class-view-filter-bar {
  border-radius: 20px !important;
}

.class-view-filter-bar .btn-primary {
  border-radius: 15px;
}

.examgrades::-webkit-outer-spin-button,
.examgrades::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.examgrades[type=number] {
  -moz-appearance: textfield;
}

.badge.badge-light-success {
  background-color: #D2FFE8;
  color: #39DA8A !important;
}

.badge.badge-light-danger {
  background-color: #FFDEDE;
  color: #FF5B5C !important;
}

.badge.badge-light-primary {
  background-color: #E2ECFF;
  color: #5A8DEE !important;
}

.class-view-graph-modal .modal-dialog {
  max-width: 95%;
}

.filter-bar {
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  /* border: 1px solid rgb(194, 196, 199); */
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.filter-bar .filter {
  flex: 1;
  position: relative;
  padding: 0 10px;
  cursor: pointer;
}

.filter-date-picker {
  position: absolute;
  left: 0;
  top: 60px;
}

.filter-bar .filter-date,
.paginate_search:focus {
  outline: none;
}

.filter-bar .filter-date,
.paginate_search {
  border: none;
  width: 100%;
  color: #858585;
}

.filter-bar .filter-unpublish {
  flex: unset;
  width: 100px;
}

.filter-bar .filter-arrow {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 12px;
}

.filter-bar .filter-title {
  font-size: 12px;
  color: #7b7b7b;
}

.filter-bar .filter-box {
  min-width: 350px;
  box-sizing: border-box;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
  border-radius: 5px;
  position: absolute;
  left: 0px;
  top: 60px;
  background-color: #fff;
  display: none;
  padding: 15px;
  text-align: center;
}

.filter-bar .filter::after {
  content: "";
  display: block;
  position: absolute;
  height: 37px;
  width: 1px;
  background-color: rgb(224, 225, 227);
  left: 100%;
  top: 5px;
}

.filter-bar .selected-types,
.selected-student,
.selected-classes {
  display: block;
  color: #858585;
}

.academic-subjects {
  display: flex;
}

.academic-subjects .subject-tag {
  padding: 3px 10px;
  margin-right: 5px;
  border-radius: 5px
}