.chat-blog-list {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    /* height: 570px !important; */
    /* overflow-y: scroll; */
}

.chat-blog-list::-webkit-scrollbar {
    display: none;
}

.chat-blog-item {
    border-radius: 10px;
    background: rgba(242, 242, 242, 0.55);
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all 400ms;
}

.chat-blog-item:hover,
.chat-blog-item.selected-blog {
    background: rgb(253 237 202 / 40%);
}

.card-blog-details .card-header,
.card-blog-details .card-footer {
    background-color: transparent !important;
}

.chat-blog-details {
    height: 380px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.chat-blog-details::-webkit-scrollbar {
    display: none;
}

.chat-message {
    margin-bottom: 20px;
}

.chat-message-name {}

.chat-message-message {
    padding: 10px 20px;
    background-color: aliceblue;
    display: inline-block;
    border-radius: 15px;
    margin: 3px 0;
}

.chat-message-timestamp {
    color: darkgray;
    font-size: 10px;
}