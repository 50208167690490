.has-pagination.grey-section {
    padding-bottom: 20px;
}
.paginate-wrap {
    display: flex;
    padding-top: 15px;
    --box: 40px;
}
.paginate-wrap button {
    width: var(--box);
    height: var(--box);
    border-radius: var(--box);
    margin: 0 5px;
    border: 0;
    background-color: var(--gray);
    transition: all 300ms;
}
.paginate-wrap button:hover {
    background-color: #7b7b7b;
}
.paginate-wrap button:hover svg {
    color: #fff;
}
.paging-info {
    line-height: var(--box);
    padding-left: 20px;
    font-size: 13px;
    color: gray
}