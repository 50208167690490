.timetable-table {
    border-collapse: separate;
    margin: 0 !important;
}
.timetable-table th {
    background-color: rgb(252, 252, 252) !important;
    font-size: 14px;
    font-weight: normal;
    font-weight: 700 !important;
    padding: 15px;
}
.timetable-table td {
    background-color: #fff !important;
    font-size: 14px;
    font-weight: 600;
    height: 60px;
}
.timetable-table th,
.timetable-table td {
    padding: 15px;
    vertical-align: middle;
}

.timetable-table thead tr:last-child th:first-child {
    border-top-left-radius: 20px;
}

.timetable-table thead tr:last-child th:last-child {
    border-top-right-radius: 20px;
}
.timetable-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

.timetable-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}
/* [dir='rtl'] .timetable-table tr:last-child td:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 20px;
}

[dir='rtl'] .timetable-table tr:last-child td:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
} */
