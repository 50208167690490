.album-picture {
    overflow: hidden;
}

.album-picture:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top,
            transparent 0%,
            rgba(0, 0, 0, 0.3) 100%);
}

.media-view-btn {
    width: 45px;
    height: 45px;
    border-radius: 25px !important;
    position: absolute;
    right: 120px;
    top: 10px;
    z-index: 1;
}

.media-dl-btn {
    width: 45px;
    height: 45px;
    border-radius: 25px !important;
    position: absolute;
    right: 65px;
    top: 10px;
    z-index: 1;
}

.picture-check-btn {
    width: 45px;
    height: 45px;
    border-radius: 25px !important;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    /* background-color: #fff !important; */
}

.album-picture .btn {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-view-btn {
    width: 25px;
    height: 25px;
    border-radius: 25px !important;
    position: absolute;
    right: 70px;
    top: 10px;
    z-index: 1;
}

.media-dl-btn {
    width: 25px;
    height: 25px;
    border-radius: 25px !important;
    position: absolute;
    right: 40px;
    top: 10px;
    z-index: 1;
}

.picture-check-btn {
    width: 25px;
    height: 25px;
    border-radius: 25px !important;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    /* background-color: #fff !important; */
}