:root {
    --CyanProcess: #15bbe7;
    --CerisePink: #ee3a81;
    --FrenchRose: #f1478b;
    --VividMalachite: #00d045;
    --Malachite: #18e05b;
    --BlueYonder: #3c63ae;
    --gray: rgba(0, 0, 0, 0.05);
    --white: #ffffff;
    --black: #000000;
    --chineseSilver: #c9c9c9;
}

/* OVERRIDES */
body {}

.btn-primary {
    background-color: #15bbe7;
    border-color: #15bbe7;
}

.btn-primary:hover {
    background-color: #10aeda !important;
    border-color: #10aeda !important;
}

.btn-primary.disabled,
.btn-primary:disabled,
fieldset:disabled .btn-primary {
    background-color: #10aeda !important;
    border-color: #10aeda !important;
}

.btn-secondary {
    background-color: #ee3a81;
    border-color: #ee3a81;
}

.btn-secondary:hover {
    background-color: #f1478b !important;
    border-color: #f1478b !important;
}

.btn-success {
    background-color: #00d045;
    border-color: #00d045;
}

.btn-success:hover {
    background-color: #18e05b !important;
    border-color: #18e05b !important;
}

.bg-success {
    background-color: #00d045 !important;
}

.btn-tertiary {
    background-color: #3c63ae !important;
    border-color: #3c63ae !important;
    color: #fff !important;
}

.grey-section {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 20px;
}

.custom-styled-form input[type=text]:not(.no-style),
.custom-styled-form textarea:not(.no-style),
.custom-styled-form input[type=phone]:not(.no-style),
.custom-styled-form input[type=number]:not(.no-style),
.custom-styled-form input[type=email]:not(.no-style),
.custom-styled-form .custom-styled-select__control:not(.no-style),
.custom-styled-form .pic-prev {
    margin-top: -10px;
}

/* Custom CSS */
#iedu-layout {
    /* height: 100vh; */
    /* min-height: 100vh; */
    display: flex;
    gap: 20px;
}

.modal-backdrop {
    z-index: 9999;
}
.second-backdrop {
    z-index: 99999;
}

.modal {
    z-index: 99999;
}

.fc a {
    text-decoration: none;
    color: #000;
}

.fc-daygrid-day-frame {
    cursor: pointer;
}

.fc-header-toolbar button {
    text-transform: uppercase !important;
}

/* RTL */
[dir='rtl'] .modal-header .btn-close {
    margin-left: 0;
}

[dir='rtl'] .form-check .form-check-input {
    float: right;
    margin-left: 0.3rem;
}

.widget-box {
    height: 200px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) -1px 2px 16px 2px;
    padding: 5px;
}

.widget-box-header {
    display: block;
    width: 100%;
    height: 50px;
    background-color: var(--CyanProcess);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 0;
    text-decoration: none;
    padding: 13px 10px 0;
}

.widget-box-list {
    padding-top: 5px;
}

.widget-box-body {
    height: 140px;
    overflow-y: scroll;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 15px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.widget-box-body::-webkit-scrollbar {
    display: none;
}

.widget-box-list-item {
    border-left: var(--CyanProcess) 5px solid;
    color: var(--CyanProcess);
    line-height: 50px;
    font-weight: 700;
    padding-left: 15px;
    font-size: 14px;
    margin-bottom: 5px;
}

.widget-box-list-item-ar {
    border-right: var(--CyanProcess) 5px solid;
    color: var(--CyanProcess);
    line-height: 50px;
    font-weight: 700;
    padding-right: 15px;
    font-size: 14px;
    margin-bottom: 5px;
}

.widget-box-list-item:last-child {
    margin-bottom: 0;
}

.cdt-search {
    border: #CFD4DA 1px solid;
    width: 250px;
    border-radius: 5px;
    display: flex;
}

.cdt-search input {
    border: 0;
    padding: 0 0 0 10px;
    background-color: transparent;
    flex: 1;
    outline: none;
}

.cdt-search button {
    border: 0;
    background-color: transparent;
    width: 30px;
    outline: none;
}

.modal-dialog.modal-xxl {
    max-width: 95%;
}
.btn-strand {
    border: #000 1px solid;
    margin-right: 10px;
    border-radius: 10px;
    padding: 5px 10px;
    background: #fff;
}
.btn-strand span {
    font-size: 12px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: #000 1px solid;
    background-color: #000;
    color: #fff;
    display: inline-block;
    margin-right: 7px;
    position: relative;
    top: -2px;
}
.btn-strand.active {
    background-color: #000;
    color: #fff;
    border: #000 1px solid;
}
.btn-strand.active span {
    background-color: transparent;
    color: #fff;
    border: #fff 1px solid;
}

.btn-creativity {
    border: #DE372B 1px solid !important;
    margin-right: 10px;
}
.btn-creativity span {
    border: #DE372B 1px solid;
    background-color: #DE372B;
}
.btn-creativity.active, .badge-creativity {
    background-color: #DE372B;
    color: #fff;
    border: #DE372B 1px solid;
}

.btn-activity {
    border: #3267F2 1px solid !important;
    margin-right: 10px;
}
.btn-activity span {
    border: #3267F2 1px solid;
    background-color: #3267F2;
}
.btn-activity.active, .badge-activity {
    background-color: #3267F2;
    color: #fff;
    border: #3267F2 1px solid;
}

.btn-service {
    border: #22BE4F 1px solid !important;
    margin-right: 10px;
}
.btn-service span {
    border: #22BE4F 1px solid;
    background-color: #22BE4F;
}
.btn-service.active, .badge-service {
    background-color: #22BE4F;
    color: #fff;
    border: #22BE4F 1px solid;
}
.Toastify__toast-container {
    z-index: 999999 !important;
}

.pagination .selected a {
    color: #fff;
}

.ck-editor-ht-200 .ck-editor__editable_inline {
    min-height: 200px;
}
div:has(> div.sweet-alert) {
    z-index: 999999 !important;
}
.cas-tabs .nav-link {
    color: #000 !important;
}
.cas-tabs .active {
    background-color: #15bbe7 !important;
    color: #fff !important;
    border: #15bbe7 1px solid !important;
    font-weight: bold;
}