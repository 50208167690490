.achievement {
    border-top-left-radius: 20px !important;
    height: 100% !important;
}
.graph-card {
    height: 100% !important;
}
.last-graph-card {
    border-top-right-radius: 20px !important;
}
.trophy {
    background-image: url(/src/assets/images/trophy.png);
    background-size: 200px auto;
    background-repeat: no-repeat;
    background-position: right bottom;
}
.thumbs-up {
    background-image: url(/src/assets/images/thumbs-up.png);
    background-size: 180px auto;
    background-repeat: no-repeat;
    background-position: calc(100% - 30px) calc(100% - 30px);
}

@media (min-width: 1601px) {
    .trophy {
        background-size: 226px auto;
    }
    .thumbs-up {
        background-size: 200px auto;
    }
}

/* RTL */
[dir='rtl'] .trophy {
    background-position: 10px bottom;
}
[dir='rtl'] .thumbs-up {
    background-position: 20px calc(100% - 30px);
}
