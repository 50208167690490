#iedu-header {
    border-radius: 45px;
    padding: 20px;
    /* height: 170px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.lite-header {
    height: 70px !important;
}

.btn-header {
    display: flex;
    width: 37px;
    height: 37px;
    background-color: #0cbbe7;
    border-radius: 100% !important;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
}

.header-back-btn {
    font-size: 30px;
    line-height: 30px;
    cursor: pointer;
}

.header-back-btn svg {
    transition: all 250ms;
}

.header-back-btn:hover svg {
    color: #ee3a81;
}

.child-list-item {
    display: flex !important;
}

.child-pic {
    width: 60px;
    /* height: 60px; */
    background-color: #0cbbe7;
    border-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-right: 10px;
    border: #0cbbe7 2px solid;
}

[dir='rtl'] .child-pic {
    width: 60px;
    /* height: 60px; */
    background-color: #0cbbe7;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-left: 10px;
    border: #0cbbe7 2px solid;
}

.list-child-pic {
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.child-name {
    padding-top: 3px;
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    margin: 0;

}

.child-name span {
    cursor: pointer;
}

.class-name {
    color: black;
    /* opacity: 0.8; */
}

.class-name span {
    cursor: pointer;
}

.class-name svg {
    font-size: 12px;
    position: relative;
    top: -2px;
}

.child-list-item {
    line-height: 20px;
    cursor: pointer;
    transition: all 300ms;
}

.child-list-item:hover {
    background-color: #0cbbe7;
    color: #fff;
}

.child-list-item .class-name,
.child-list-item .child-pic,
.child-list-item .child-pic svg {
    transition: all 300ms;
}

.child-list-item:hover .class-name {
    color: #fff;
}

.child-list-item:hover .child-pic {
    background-color: #fff;
    border-color: #fff;
}

.child-list-item:hover .child-pic svg {
    color: #0cbbe7;
}

.child-pic img {
    transform: scale(1.5);
}

#selected-child {
    background-color: hsla(0, 0%, 100%, 0.1);
    display: inline-flex;
    border-radius: 30px;
    padding-right: 25px;
    backdrop-filter: saturate(180%) blur(5px);
}

[dir='rtl'] #selected-child {
    background-color: hsla(0, 0%, 100%, 0.1);
    display: inline-flex;
    border-radius: 30px;
    padding-right: 0;
    padding-left: 25px;
    backdrop-filter: saturate(180%) blur(5px);
}